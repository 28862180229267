import React from "react";
import Page from "../../components/Page";
import Title from '../../components/Title'
import Para from '../../components/Para'
import withRoot from '../../utils/withRoot'

const Privacy
    = props => {
    return (
        <Page title="Privacy Policy">
            <Para>Your privacy is important to Ashridge Technologies.  This privacy statement provides
                information about the personal information that Ashridge Technologies collects, and
                the ways in which Ashridge Technologies uses that personal information.</Para>

            <Title>Personal information collection</Title>

            <Para>Ashridge Technologies may collect and use the following kinds of personal information:
            </Para>

            <ul>
                <li>information about your use of this website;
                </li>
                <li>information that you provide for the purpose of registering with the
                    website (including your full name and email address);</li>
                <li>any other information that you send to Ashridge Technologies.</li>
            </ul>

            <Title>Using personal information</Title>

            <Para id='using'>Ashridge Technologies may use your personal information to:
            </Para>

            <ul>
                <li>administer this website;</li>
                <li>personalize the website for you;</li>
                <li>enable your access to and use of the website services;</li>
                <li>publish information about you on the website;</li>
                <li>supply to you services that you purchase;</li>
                <li>send to you statements and invoices;</li>
                <li>collect payments from you; and</li>
                <li>send you marketing communications about Ashridge Technologies's activities.</li>
            </ul>

            <Para>Ashridge Technologies will stop sending you marketing communications if you request it to do so.</Para>

            <Para>Where Ashridge Technologies discloses your personal information to its agents or
                sub-contractors for these purposes, the agent or sub-contractor in
                question will be obligated to use that personal information in
                accordance with the terms of this privacy statement.
            </Para>

            <Para>In addition to the disclosures reasonably necessary for the purposes
                identified elsewhere above, Ashridge Technologies may disclose your personal
                information to the extent that it is required to do so by law, in
                connection with any legal proceedings or prospective legal
                proceedings, and in order to establish, exercise or defend its legal
                rights.</Para>

            <Title id="sharing">Sharing personal information</Title>
            <Para>Ashridge Technologies will <em>not</em> share your personal information with third parties for purposes unrelated to its own activities listed <a href="#using">above</a>.</Para>

            <Title>Securing your data</Title>

            <Para>Ashridge Technologies will take reasonable technical and organisational precautions to
                prevent the loss, misuse or alteration of your personal information.
            </Para>

            <Para>Ashridge Technologies will store all the personal information you provide on secure
                servers.
            </Para>

            <Para>Information relating to electronic transactions entered into via this website
                will be protected by encryption technology.</Para>

            <Title>Cross-border data transfers</Title>

            <Para>Information that Ashridge Technologies collects may be stored and processed in and transferred
                between any of the countries in which Ashridge Technologies's partners and suppliers
                locate their physical servers and storage devices.</Para>

            <Para>Information that Ashridge Technologies collects may be stored in and transferred
                between any of the countries in which Ashridge Technologies operates to enable the
                use of the information in accordance with this privacy policy.</Para>

            <Para>In addition, personal information that you submit for publication on the
                website will be published on the internet and may be available around
                the world.</Para>

            <Para>You agree to such cross-border transfers of personal information.</Para>

            <Title>Updating this statement</Title>

            <Para>Ashridge Technologies may update this privacy policy by posting a new version on this
                website.
            </Para>

            <Para>You should check this page occasionally to ensure you are familiar with
                any changes.
            </Para>

            <Title>Other websites</Title>

            <Para>This website contains links to other websites.
            </Para>

            <Para>Ashridge Technologies is not responsible for the privacy policies or practices of any third
                party.</Para>

            <Title>Contact Ashridge Technologies</Title>

            <Para>If you have any questions about this privacy policy or Ashridge Technologies's
                treatment of your personal information, please email paulb@ashridgetech.com</Para>

        </Page>
    )
};

export default withRoot(Privacy);
